<template>
  <div>  
     <b-row
      v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
      class="content-header"
    >
      <!-- Content Left -->
      <b-col class="content-header-left" cols="12" md="5">
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h2 class="content-header-title float-left pr-1 mb-0">
              {{ $route.meta.pageTitle }}
            </h2>

            <div class="breadcrumb-wrapper">
              <b-breadcrumb>
                <b-breadcrumb-item to="/">
                  <feather-icon
                    icon="HomeIcon"
                    size="16"
                    class="align-text-top"
                  />
                </b-breadcrumb-item>
                <b-breadcrumb-item
                  v-for="item in $route.meta.breadcrumb"
                  :key="item.text"
                  :active="item.active"
                  :to="item.to"
                  >{{ item.text }}</b-breadcrumb-item
                >
              </b-breadcrumb>
            </div>
          </b-col>
        </b-row>
      </b-col>

      <!-- Content Right -->
      <b-col class="content-header-right text-md-right" md="7" cols="12">
        <search-pending-payments />
      </b-col>
    </b-row> 
    <b-nav card-header pills class="mt-2 mb-0 mx-0">
      <div class="d-flex justify-content-between w-100">
        <div class="d-flex align-items-end">
          <b-nav-item
            :to="{ name: $route.meta.pendingPaymentTab }"
            exact
            exact-active-class="active"
            :link-classes="['px-3', bgTabsNavs]"
            :active="$route.matched[2].name == $route.meta.pendingPaymentTab"
          >
            
            <span>Automatic Declined</span>
            <feather-icon v-if="G_PENDING_PAYMENTS_COUNTER.length > 0 "
              icon
              :badge="
                  G_PENDING_PAYMENTS_COUNTER.length > 0 ? G_PENDING_PAYMENTS_COUNTER[0].total_automatic : 0
              " 
              badge-classes="badge-danger"
              class="ml-2"
            /> 
          </b-nav-item>
          <b-nav-item
            :to="{ name: $route.meta.manualTab }"
            exact
            exact-active-class="active"
            :link-classes="['px-3', bgTabsNavs]"
            class="mx-1"
            :active="$route.matched[2].name == $route.meta.manualTab"
          >
            
            <span>Manual </span>
            <feather-icon v-if="G_PENDING_PAYMENTS_COUNTER.length > 0 "
              icon
              :badge="
                  parseInt(G_PENDING_PAYMENTS_COUNTER[0].sum_manual)
              " 
              badge-classes="badge-danger"
            /> 
          </b-nav-item>
          <b-nav-item
            :to="{ name: $route.meta.othersTab }"
            exact
            exact-active-class="active"
            :link-classes="['px-3', bgTabsNavs]"
            :active="$route.matched[2].name == $route.meta.othersTab"
          > 
            <span >Others </span>
            <feather-icon v-if="G_PENDING_PAYMENTS_COUNTER.length > 0 "
              icon
              :badge="
                  parseInt(G_PENDING_PAYMENTS_COUNTER[0].sum_others)
              " 
              badge-classes="badge-danger"
            /> 
          </b-nav-item>
        </div>
        <div class="d-flex pending-payments-filters-v2">  
          <b-button 
            variant="outline-dark"
            class="btn-icon mr-1 d-flex"
            :style="isDarkSkin ? 'border-color: #ffffff66 !important;':'border-color: #d8d6de !important'  "  
            @click="refresh"
          >
              <feather-icon v-if="!timerActive" icon="RefreshCcwIcon"  size="15" /> 
              <div v-else>
                <b-spinner small class="" />
                <span>{{ timer }}</span>
              </div>
               
          </b-button>
          <v-select
            class="mr-1"
            :options="optionsMonths"
            v-model="currentMonth"
            label="name"
            :reduce="(option) => option.id"
            :clearable="false"
            @input="changeCurrentMonth"
          ></v-select>

          <v-select
            class="mr-1"
            :options="optionsYears"
            v-model="currentYear"
            label="name"
            :reduce="(option) => option.id"
            :clearable="false" 
            @input="changeCurrentYear"
          ></v-select>
        </div>
      </div>
    </b-nav>

    <b-card class="border-top-primary border-3 border-table-radius px-0">
      <router-view :key="$route.path" />
    </b-card>
  </div>
</template>

<script>
import SearchPendingPayments from "../../ce-digital/sub-modules/financial/view/pending-payments/view/components/search/SearchPendingPaiments.vue";
import { mapMutations, mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
  components: { SearchPendingPayments },
  data() {
    return {
      currentYear: null,
      currentMonth: null,
      optionsYears: [],
      optionsMonths: [
        { id: 1, name: "JAN" },
        { id: 2, name: "FEB" },
        { id: 3, name: "MAR" },
        { id: 4, name: "APR" },
        { id: 5, name: "MAY" },
        { id: 6, name: "JUNE" },
        { id: 7, name: "JULY" },
        { id: 8, name: "AUG" },
        { id: 9, name: "SEP" },
        { id: 10, name: "OCT" },
        { id: 11, name: "NOV" },
        { id: 12, name: "DEC" },
      ],
      timerActive:false,
      timer: moment(10 * 1 * 1000).format("mm:ss"),
    };
  },

  methods: {
    ...mapMutations({
      M_SET_PENDING_PAYMENTS_MONTH:
        "FinancialStore/M_SET_PENDING_PAYMENTS_MONTH",
      M_SET_PENDING_PAYMENTS_YEAR: "FinancialStore/M_SET_PENDING_PAYMENTS_YEAR",
    }),
    ...mapActions({
            A_PENDING_PAYMENTS_COUNTER:
        "FinancialStore/A_PENDING_PAYMENTS_COUNTER",
            A_PENDING_PAYMENTS_REFRESH:
        "FinancialStore/A_PENDING_PAYMENTS_REFRESH",
    }),
    generateYears() {
      const today = new Date();
      const currentYear = this.G_PENDING_PAYMENTS_YEAR ? this.G_PENDING_PAYMENTS_YEAR :  today.getFullYear();
      this.optionsYears = [];

      for (let year = 2019; year <= currentYear; year++) {
        this.optionsYears.push({
          id: year,
          name: "" + year,
        });
      }

      this.currentYear = currentYear;
    },

    setCurrentMonth() {
      const today = new Date();
      const currentMonth = this.G_PENDING_PAYMENTS_MONTH ? this.G_PENDING_PAYMENTS_MONTH : today.getMonth() + 1; 
      this.currentMonth = this.optionsMonths.filter(
        (element) => element.id == currentMonth
      )[0].id;
    },

    async changeCurrentMonth() {  
      await this.M_SET_PENDING_PAYMENTS_MONTH(this.currentMonth); 
      if(this.getDay == 30 && this.currentMonth == "02"){ 
        this.$router.push({ name: 'pending-payments-automatic-declined' });
      }
      await this.A_PENDING_PAYMENTS_COUNTER({year: this.currentYear, month : parseInt(this.currentMonth) , program_id : this.programId});
    },
    async changeCurrentYear() {
      await this.M_SET_PENDING_PAYMENTS_YEAR(this.currentYear);
      await this.A_PENDING_PAYMENTS_COUNTER({year: this.currentYear, month : parseInt(this.currentMonth) , program_id : this.programId});
    },
    async initTimer() {
      this.timerActive = true;
      this.timer = moment(10 * 1 * 1000).format("mm:ss");
      const reloj = moment(10 * 1 * 1000);
      const timer = setInterval(async () => {
        this.timer = reloj.format("mm:ss");
        reloj.subtract(1, "seconds");
        if (this.timer === "00:00") {
          this.timerActive = false;
          clearInterval(timer);
        }
      }, 1000);
    },
    async refresh(){
      if(!this.timerActive){
        await this.initTimer();
        this.A_PENDING_PAYMENTS_REFRESH();
      }

    }
  },
  computed:{
    getDay(){
      return this.$route.params.day;
    }, 
   ...mapGetters({ 
    G_PENDING_PAYMENTS_COUNTER: "FinancialStore/G_PENDING_PAYMENTS_COUNTER",
          G_PENDING_PAYMENTS_MONTH: "FinancialStore/G_PENDING_PAYMENTS_MONTH",
      G_PENDING_PAYMENTS_YEAR: "FinancialStore/G_PENDING_PAYMENTS_YEAR",
      G_PENDING_PAYMENTS_REFRESH: "FinancialStore/G_PENDING_PAYMENTS_REFRESH",
      
    }),
    programId() {
      return this.$route.matched[0].meta.program ?? this.$route.matched[1].meta.program_id;
    },
  },
  created() {
    
    this.generateYears();
  },

  async mounted() { 
    await this.setCurrentMonth(); 
    await this.A_PENDING_PAYMENTS_COUNTER({year: this.currentYear, month : parseInt(this.currentMonth) , program_id : this.programId});

  }, 

  watch:{
    async G_PENDING_PAYMENTS_REFRESH(){
      await this.A_PENDING_PAYMENTS_COUNTER({year: this.currentYear, month : parseInt(this.currentMonth) , program_id : this.programId});
    }
  }
};
</script>

<style lang="scss" scoped>
.feather-icon{
  margin-left: 25px;
}
.pending-payments-filters-v2 {
  padding-bottom: 4px;

  .v-select {
    min-width: 110px;
  }
}
</style>
