<template>
  <div>
    <b-row class="w-100 m-0">
      <b-col cols="12" class="pl-0 pr-0">
        <b-input-group class="mr-1">
          <b-input-group-prepend>
            <b-button variant="outline-info"
              >PENDING PAYMENTS GLOBAL SEARCH</b-button
            >
          </b-input-group-prepend>
          <b-form-input
            v-model="search.text"
            placeholder="Search by First Name, Last Name or the last 4 digits of the Phone Number"
            @keyup.enter="openModal"
            style="padding: 18px 0 18px 8px"
          />
          <b-input-group-append>
            <b-button variant="primary" @click="openModal">
              <feather-icon icon="SearchIcon" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>

    <modal-search-pending-payment
      v-if="modalState"
      :search="search"
      @closeModal="modalState = false"
    />
  </div>
</template>

<script>
import ModalSearchPendingPayment from "../modal/ModalSearchPendingPayment.vue";

export default {
  components: { ModalSearchPendingPayment },
  data() {
    return {
      modalState: false,
      search: {
        text: "",
      },
    };
  },
  methods: {
    openModal() {
      if (this.search.text.length > 0) {
        this.modalState = true;
      } else {
        this.modalState = false;
      }
    },
  },
};
</script>

<style></style>
