export default [
    {
        key: 'client_name',
        label: 'Client',
        visible: true,
    },
    // {
    //   key: 'mobile',
    //   label: 'Mobile',
    //   thClass: 'text-center',
    //   tdClass: 'text-center pt-2',
    //   visible: true,
    // },
    {
        key: 'is_app_activated',
        label: 'App',
        thClass: 'text-center',
        tdClass: 'text-center pt-2',
        visible: true,
    },
    // {
    //   key: 'start_date',
    //   label: 'Start Date',
    //   thClass: 'text-center',
    //   tdClass: 'text-center pt-2',
    //   visible: true,
    // },
    {
        key: 'client_type',
        label: 'Type',
        thClass: 'text-center',
        tdClass: 'text-center pt-2',
        visible: true,
        sortable: true,
    },
    {
        key: 'type_month',
        label: 'month',
        thClass: 'text-center',
        tdClass: 'text-center pt-2',
        visible: true,
        sortable: true,
    },

    {
        key: 'day_payment',
        label: 'day',
        thClass: 'text-center',
        tdClass: 'text-center pt-2',
        visible: true,
    },
    {
        key: 'last_payment',
        label: 'Last Payment',
        thClass: 'text-center',
        tdClass: 'text-center pt-2',
        visible: true,
    },
    {
        key: 'balance',
        visible: true,
        thClass: 'text-center',
        tdClass: 'text-center',
    },
    {
        key: 'amount',
        visible: true,
        thClass: 'text-center',
        tdClass: 'text-center',
    },
    // {
    //     key: 'zero_payment',
    //     label: 'Zero',
    //     thClass: 'text-center',
    //     tdClass: 'text-center ',
    //     visible: true,
    // },
    {
        key: 'count_call',
        label: 'Counters',

        tdClass: 'text-center pt-2',
        visible: true,
    },
    // {
    //   label: 'SMS',
    //   key: 'count_sms',
    //   thClass: 'text-center',
    //   tdClass: 'text-center pt-2',
    //   visible: true,
    // },

    // {
    //   label: 'Noti',
    //   key: 'count_not',
    //   thClass: 'text-center',
    //   tdClass: 'text-center pt-2',
    //   visible: true,
    // },
    // {
    //   key: 'tracking',
    //   label: 'Comm',
    //   thClass: 'text-center',
    //   tdClass: 'text-center pt-2',
    //   visible: true,
    // },
    {
        key: "contact_schedule",
        label: "CT",
    },
    // {
    //   label: "Result",
    //   key: "client_result",
    // },
    // {
    //   label: "Label",
    //   key: "client_label",
    // },
    {
        key: 'actions',
        label: 'actions',
        thClass: 'text-center',
        tdClass: 'text-center pt-1',
        visible: true,
    },

    {
        key: "client_state",
        label: "St"
    }
]
