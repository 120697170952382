<template>
  <div>
    <b-modal
      ref="search-digital-modal"
      title="PENDING PAYMENTS GLOBAL SEARCH"
      v-model="modalSearch"
      size="xlg"
      modal-class="modal-primary text-center"
      hide-footer
      @hidden="closeModal"
      title-tag="h3"
    >
      <!-- table -->

      <b-table
        ref="refSearchPendingPayment"
        small
        :items="myProvider"
        :fields="fields"
        primary-key="id"
        table-class="text-nowrap"
        responsive="sm"
        show-empty
        sticky-header="70vh"
        :busy="isBusy"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>
        <template #cell(client_name)="data">
          <div>
            <p
              class="m-0 cursor-pointer text-primary text-left"
              @click="openModalClientDetail(data.item)"
            >
              {{ data.item.client_name }}
            </p>
            <p class="m-0 text-left">
              {{ data.item.account }}
            </p>
            <badge-risky-clients
              :idAccount="data.item.client_account_id"
              :riskLevel="data.item.risk_level"
              :riskDescription="data.item.risk_description"
              :riskType="data.item.risk_type"
            />
            <b-badge
              v-if="data.item.reschedule == 'active'"
              pill
              style="padding: 6px 8px"
              variant="primary"
            >
              <feather-icon icon="CalendarIcon" />
              SCHEDULED CHARGE
            </b-badge>
            <b-badge
              v-if="data.item.zero_payment_pending === 1"
              pill
              style="padding: 6px 8px"
              variant="primary"
            >
              PENDING (Zero Payment)
            </b-badge>
            <b-badge
              v-if="data.item.other_pending === 1"
              pill
              style="padding: 6px 8px"
              variant="info"
            >
              PENDING (Others)
            </b-badge>
            <b-badge
              pill
              style="padding: 6px 8px"
              variant="primary"
              v-if="data.item.pay_state == 'igual'"
            >
              LAST PAYMENT
            </b-badge>
            <b-badge
              pill
              style="padding: 6px 8px"
              variant="danger"
              v-if="data.item.pay_state == 'mayor'"
            >
              OVER FLOW
            </b-badge>
          </div>
        </template>
        <template #cell(day_payment)="data">
          <div>
            <types-of-payments
              :type-payment="{
                type_payment: data.item.type_payment,
                day_payment: data.item.day_payment,
              }"
            />
          </div>
        </template>
        <template #cell(last_payment)="data">
          <div>
            <p class="m-0">
              {{ data.item.last_payment | myGlobal }}
            </p>
          </div>
        </template>
        <template #cell(type_month)="data">
          <div class="d-flex flex-column">
            <b-badge pill variant="light-primary">
              {{ typeMonth(data.item.type_month) }}
              <p v-if="data.item.type_month < 4" class="text-warning m-0">
                (Of the month)
              </p></b-badge
            >
          </div>
        </template>
        <template #cell(start_date)="data">
          <div>
            <p class="m-0">
              {{ data.item.start_date | myGlobal }}
            </p>
          </div>
        </template>
        <template #cell(balance)="data">
          <div>
            <b-button
              variant="outline"
              class="m-0 p-0 pt-1"
              @click="openBalance(data.item)"
            >
              <money
                v-model="data.item.balance"
                class="m-0 p-0"
                v-bind="maskMoney"
                style="
                  border: 0px solid transparent;
                  color: #f74f4f;
                  text-align: center;
                  cursor: pointer;
                  width: 100px;
                "
                disabled
              />
            </b-button>
          </div>
        </template>

        <template #cell(zero_payment)="data">
          <div class="pt-1">
            <template>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 21.73 24.69"
                @click="zeroPayment(data.item)"
                :width="25"
                :height="25"
                v-b-tooltip.hover.right="'Zero Payment'"
                class="cursor-pointer"
              >
                >
                <g id="Capa_2" data-name="Capa 2">
                  <g id="Capa_1-2" data-name="Capa 1" fill="#b18922">
                    <path
                      d="M12.75,0c0,.77,0,1.54,0,2.31a3.62,3.62,0,0,1-.93,2.57.56.56,0,0,0-.05.08c.47.57,1,1.14,1.4,1.71a24,24,0,0,1,2.91,4.43,21.52,21.52,0,0,1,1.21,3.09.63.63,0,0,0,.51.48,5.07,5.07,0,0,1,3.47,7,4.86,4.86,0,0,1-3.75,3,4.85,4.85,0,0,1-4.37-1.3,6.14,6.14,0,0,1-.9-1.12.84.84,0,0,0-.77-.41c-1.88,0-3.76,0-5.65,0a6,6,0,0,1-2.33-.41A5.57,5.57,0,0,1,.25,14.53,21.24,21.24,0,0,1,3.39,8,20.62,20.62,0,0,1,5.52,5.45l.39-.36-.13-.17a4.13,4.13,0,0,1-.94-2.67c0-.75,0-1.5,0-2.25Zm3.19,14.5c-.35-.86-.65-1.69-1-2.49a19.61,19.61,0,0,0-4.35-6A.77.77,0,0,0,10,5.79H7.64A.93.93,0,0,0,7,6.08c-.31.29-.62.59-.9.9a19.07,19.07,0,0,0-3.61,5.56,15.74,15.74,0,0,0-1,2.78,4,4,0,0,0,.73,3.36,4.1,4.1,0,0,0,3.44,1.68h5.85C11.35,17.18,12.85,15.26,15.94,14.5Zm4.31,5.11a3.65,3.65,0,0,0-7.29,0,3.65,3.65,0,1,0,7.29,0Zm-9-18.18H6.31V2.56a2.16,2.16,0,0,0,.42,1.22,1.16,1.16,0,0,0,.95.53H9.91A1,1,0,0,0,10.65,4,2.94,2.94,0,0,0,11.25,1.43Z"
                    />
                    <path
                      d="M14.38,20.67l1.22-1-1.27-1.17,1.16-1,1.06,1.27,1.22-1.43,1,1.08-1.24,1.09,1.32,1.13-1.12,1-1.06-1.18-1.13,1.26Z"
                    />
                    <path
                      d="M11,13.77a3.07,3.07,0,0,1-.53,2A1.82,1.82,0,0,1,9,16.41a1.89,1.89,0,0,1-1.55-.66,3,3,0,0,1-.54-1.91V12.7a3.09,3.09,0,0,1,.53-2A1.87,1.87,0,0,1,9,10.06a1.87,1.87,0,0,1,1.55.67,3,3,0,0,1,.54,1.9ZM9.82,12.52a2.26,2.26,0,0,0-.21-1.12A.72.72,0,0,0,9,11.05a.7.7,0,0,0-.64.33,2.13,2.13,0,0,0-.22,1v1.5a2.36,2.36,0,0,0,.21,1.13.71.71,0,0,0,.66.36.68.68,0,0,0,.65-.35A2.38,2.38,0,0,0,9.82,14Z"
                    />
                  </g>
                </g>
              </svg>
            </template>
          </div>
        </template>
        <template #cell(amount)="data">
          <div class="d-flex justify-content-center">
            <money
              v-model="data.item.monthly_amount"
              class="m-0 p-0"
              v-bind="maskMoney"
              style="
                border: 0px solid transparent;
                color: #8c8996;
                text-align: center;
                cursor: pointer;
                width: 100px;
              "
              disabled
            />

            <b-button
              variant="outline"
              class="m-0"
              @click="processPayment(data.item)"
            >
              <feather-icon
                icon="CheckCircleIcon"
                size="20"
                class="text-success"
                v-b-tooltip.hover.right="'Process Payment'"
              />
            </b-button>
          </div>
        </template>

        <template #cell(count_call)="data">
          <div
            style="
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: space-between;
              margin-top: -10px;
            "
          >
            <div style="margin-bottom: 5px">
              <b-badge
                class="cursor-pointer"
                variant="primary"
                @click="openCallsAndSmsTrackingModal(data.item, 1)"
                >{{
                  data.item.count_call === null ? 0 : data.item.count_call
                }}</b-badge
              >
              Calls
            </div>
            <div style="margin-bottom: 5px">
              <b-badge
                class="cursor-pointer"
                variant="primary"
                @click="openCallsAndSmsTrackingModal(data.item, 2)"
                >{{
                  data.item.count_sms === null ? 0 : data.item.count_sms
                }}</b-badge
              >
              SMS
            </div>
            <div style="margin-bottom: 5px">
              <b-badge
                class="cursor-pointer"
                variant="primary"
                @click="openCallsAndSmsTrackingModal(data.item, 4)"
                >{{
                  data.item.count_not === null ? 0 : data.item.count_not
                }}</b-badge
              >
              Noti
            </div>
          </div>
        </template>

        <template #cell(count_sms)="data">
          <b-badge
            class="cursor-pointer"
            variant="primary"
            @click="openCallsAndSmsTrackingModal(data.item, 2)"
            >{{
              data.item.count_sms === null ? 0 : data.item.count_sms
            }}</b-badge
          >
        </template>

        <template #cell(count_not)="data">
          <b-badge
            class="cursor-pointer"
            variant="primary"
            @click="openCallsAndSmsTrackingModal(data.item, 4)"
            >{{
              data.item.count_not === null ? 0 : data.item.count_not
            }}</b-badge
          >
        </template>
        <!-- 
        <template #cell(tracking)="data">

          <b-badge
            class="cursor-pointer"
            variant="primary"
            @click="openCallsAndSmsTrackingModal(data.item, 3)"
            >{{
              data.item.count_commentaries === null ? 0 : data.item.count_commentaries
            }}</b-badge
          >

        </template> -->

        <template #cell(contact_schedule)="data">
          <div class="pt-1"></div>
          <ShowMoreText :text="data.item.contact_schedule" />
        </template>

        <template #cell(client_state)="data">
          <div class="pt-1">{{ data.item.client_state }}</div>
        </template>

        <template #cell(actions)="data">
          <b-dropdown variant="link" no-caret boundary="viewport">
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="openAddCallsAndSmsModal(data.item, 1)">
              <feather-icon icon="PhoneCallIcon" class="mr-1" />
              <span>Add Call</span>
            </b-dropdown-item>

            <b-dropdown-item @click="openAddSmsModal(data.item, 2)">
              <feather-icon icon="MessageCircleIcon" class="mr-1" />
              <span>Send SMS</span>
            </b-dropdown-item>

            <b-dropdown-item @click="openAddCallsAndSmsModal(data.item, 3)">
              <feather-icon icon="PlusIcon" class="mr-1" />
              <span>Add Conversation</span>
            </b-dropdown-item>

            <b-dropdown-item @click="openNotificationModal(data.item)">
              <tabler-icon icon="NotificationIcon" class="mr-1" />
              <span>Send Notification</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="data.item.reschedule !== 'active' && isAgent"
              @click="openReschedulePendingPaymentModal(data.item)"
            >
              <feather-icon icon="DollarSignIcon" class="mr-1" />
              <span>Schedule Pending Payment</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>

      <add-sms-modal
        v-if="addSmsModal"
        :client-data="clientData"
        :action-type="actionType"
        @closeModal="addSmsModal = false"
        @refresh="$refs['refSearchPendingPayment'].refresh()"
      />
      <modal-process-payment
        v-if="stateProcessPayment"
        :payment-information="paymentInformation"
        :type-tab="typeTab"
        @close="stateProcessPayment = false"
        @hideModal="stateProcessPayment = false"
        @refreshTable="$refs['refSearchPendingPayment'].refresh()"
      />
      <modal-balance
        v-if="stateBalance"
        :payment-information="paymentInformation"
        @hideModal="stateBalance = false"
      />
      <add-zero-payment
        v-if="stateZeroPayment"
        :client="client"
        :type-tab="typeTab"
        @hideModal="stateZeroPayment = false"
        @refresh="$refs['refSearchPendingPayment'].refresh()"
      />
      <reschedule-pending-payment-modal
        v-if="reschedulePendingPaymentModal"
        :client-data="clientData"
        @closeModal="closeModalSms"
        @refresh="$refs['refSearchPendingPayment'].refresh()"
      />

      <add-calls-and-sms-modal
        v-if="addCallsAndSmsModal"
        :client-data="clientData"
        :action-type="actionType"
        @closeModal="closeModalSms"
        @refresh="$refs['refSearchPendingPayment'].refresh()"
      />

      <calls-and-sms-tracking
        v-if="callsAndSmsTrackingModal"
        :client-data="clientData"
        :type-tracking="typeTracking"
        @closeModal="closeModalSms"
      />
      <modal-client-detail
        v-if="modalClientDetailC"
        @hideModal="modalClientDetailC = false"
        :client-information="clientInformationT"
      />
      <notification-app-modal
        v-if="notificationAppModalOn"
        :client-selected="clientSelected"
        @close="notificationAppModalOn = false"
        @refresh="$refs['refSearchPendingPayment'].refresh()"
      />

      <b-modal
      v-if="showModalDashboardPayments"
      v-model="showModalDashboardPayments"
      @hidden="showModalDashboardPayments = false"
      size="xl"
      hide-footer
      title="Dashboard Payment"
    >
      <dashboard-payments
        v-if="showModalDashboardPayments"
        :client-account-id="paymentInformation.client_account_id"
        :read-only="true"
      ></dashboard-payments>
    </b-modal>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import StatusPayment from "@/views/commons/components/clients/stylescomponents/StatusPayment.vue";
import Feather from "@/views/ui/feather/Feather";
import ReschedulePendingPaymentModal from "@/views/ce-digital/sub-modules/financial/view/calls-and-sms/components/modals/ReschedulePendingPaymentModal.vue";
import AddCallsAndSmsModal from "@/views/ce-digital/sub-modules/financial/view/calls-and-sms/components/modals/AddCallsAndSmsModal.vue";
import CallsAndSmsTracking from "@/views/ce-digital/sub-modules/financial/view/calls-and-sms/components/modals/CallsAndSmsTrackingModal.vue";
import NotificationAppModal from "@/views/ce-digital/sub-modules/financial/view/pending-payments/view/components/modal/NotificationAppModal.vue";
import AddSmsModal from "@/views/ce-digital/sub-modules/financial/view/calls-and-sms/components/modals/AddSmsModal.vue";
import AddZeroPayment from "@/views/ce-digital/sub-modules/financial/view/pending-payments/view/components/modal/AddZeroPayment.vue";
import TypesOfPayments from "../TypesOfPayments.vue";
import PendingPaymentsService from "../../../service/pending-payments.service";
import ModalProcessPayment from "../modal/ModalProcessPayment.vue";
import ModalBalance from "../modal/ModalBalance.vue";
import BadgeRiskyClients from "@/views/commons/components/risky-clients/BadgeRiskyClients";
import ModalClientDetail from "../modal/ModalClientDetail.vue";
import fieldsData from "@/views/ce-digital/sub-modules/financial/view/pending-payments/view/data/fields.globalSearch.js";
import ShowMoreText from "@/views/ce-digital/sub-modules/financial/view/clients/components/ShowMoreText.vue";
import DashboardPayments from "@/views/commons/components/clients/dashboard/options/pay-module/components/DashboardPayments.vue";

export default {
  components: {
    AddSmsModal,
    CallsAndSmsTracking,
    Feather,
    StatusPayment,
    ModalProcessPayment,
    ReschedulePendingPaymentModal,
    AddCallsAndSmsModal,
    TypesOfPayments,
    AddZeroPayment,
    ModalBalance,
    ModalClientDetail,
    NotificationAppModal,
    BadgeRiskyClients,
    ShowMoreText,
    DashboardPayments,
  },
  props: {
    search: {
      type: Object,
    },
  },
  data() {
    return {
      showModalDashboardPayments: false,
      modalSearch: this.stateShearch,
      data: [],
      fields: fieldsData,
      isBusy: false,
      modalStateV: false,
      clientData: {},
      maskMoney: {
        decimal: ".",
        thousands: ",",
        prefix: "$",
        precision: 2,
        masked: false,
        maxlength: 11,
      },
      // Modals
      stateBalance: false,
      stateZeroPayment: false,
      reschedulePendingPaymentModal: false,
      addCallsAndSmsModal: false,
      callsAndSmsTrackingModal: false,
      modalClientDetailC: false,
      notificationAppModalOn: false,
      addSmsModal: false,
      stateProcessPayment: false,
      typeTab: 0,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_PROGRAMS_IN_NEW_PAYMENT: "TransactionStatusStore/G_PROGRAMS",
    }),

    programId() {
      return this.$route.matched[0].meta.program;
    },
  },
  mounted() {
    this.toggleModal("search-digital-modal");
  },

  methods: {
    typeMonth(item) {
      switch (item) {
        case 1:
          return "Automatic declined"; // Automatic
          break;
        case 2:
          return "Manual"; // Manual
          break;
        case 3:
          return "Others"; // Other
          break;
        case 4:
          return "One month"; // One month
          break;
        case 5:
          return "Two months"; // Two months
          break;
        case 6:
          return "Three months"; // Three months
          break;
      }
    },

    processPayment(item) {
      this.paymentInformation = item;
      this.stateProcessPayment = true;
      this.typeTab = item.type_month;
    },
    openNotificationModal(item) {
      if (item.mobile !== null) {
        this.clientSelected = {
          account: item.account,
          name: item.client_name,
          client_account_id: item.client_account_id,
          mobile: item.mobile,
        };
        this.notificationAppModalOn = true;
      } else {
        this.showToast(
          "warning",
          "top-right",
          "Warning!",
          "AlertTriangleIcon",
          "This client don't have a mobile registered."
        );
      }
    },
    async myProvider() {
      try {
        const { status, data } =
          await PendingPaymentsService.searchPendingPayments({
            name_text: this.search.text,
          });

        if (status == 200) {
          return data || [];
        }
      } catch (error) {
        console.log(error);
      }
    },
    openReschedulePendingPaymentModal(item) {
      this.clientData = item;
      if (this.clientData.mobile !== null) {
        this.reschedulePendingPaymentModal = true;
      } else {
        this.showToast(
          "warning",
          "top-right",
          "Warning!",
          "AlertTriangleIcon",
          "This client don't have a mobile registered."
        );
      }
    },
    openAddCallsAndSmsModal(item, type) {
      this.clientData = item;
      this.actionType = type;
      if (this.clientData.mobile !== null) {
        this.addCallsAndSmsModal = true;
      } else {
        this.showToast(
          "warning",
          "top-right",
          "Warning!",
          "AlertTriangleIcon",
          "This client don't have a mobile registered."
        );
      }
    },
    openCallsAndSmsTrackingModal(item, type) {
      this.clientData = item;
      this.typeTracking = type;
      if (this.clientData.mobile !== null) {
        this.callsAndSmsTrackingModal = true;
      } else {
        this.showToast(
          "warning",
          "top-right",
          "Warning!",
          "AlertTriangleIcon",
          "This client don't have a mobile registered."
        );
      }
    },
    openAddSmsModal(item, type) {
      this.clientData = item;
      this.actionType = type;
      if (this.clientData.mobile !== null) {
        this.addSmsModal = true;
      } else {
        this.showToast(
          "warning",
          "top-right",
          "Warning!",
          "AlertTriangleIcon",
          "This client don't have a mobile registered."
        );
      }
    },
    closeModalSms() {
      this.addCallsAndSmsModal = false;
      this.reschedulePendingPaymentModal = false;
      this.callsAndSmsTrackingModal = false;
      this.addSmsModal = false;
    },
    closeModal() {
      this.$emit("closeModal");
    },
    zeroPayment(item) {
      this.stateZeroPayment = true;
      this.client = {
        client_name: item.client_name,
        program_name: "Credit Experts",
        client_type: item.client_type,
        client_account_id: item.client_account_id,
        client_account: item.account,
      };
      this.typeTab = item.type_month;
    },
    openBalance(item) {
      this.paymentInformation = item;
      if (this.G_PROGRAMS_IN_NEW_PAYMENT.includes(this.programId)) {
        this.showModalDashboardPayments = true;
      } else {
        this.stateBalance = true;
      }
    },
    openModalClientDetail(client) {
      this.modalClientDetailC = true;
      this.clientInformationT = client;
    },
  },
};
</script>

<style scoped>
#icon {
  border-radius: 50%;
  margin-bottom: 2px;
  margin-right: 5px;
}
.my {
  margin: 6px 0 0 6px;
}
</style>
